import { all } from 'redux-saga/effects';
import metadata from './app/metadata';
import verification from './app/verification';
import server from './app/server';

const rootSaga = function* root() {
  yield all([...metadata, ...verification, ...server]);
};

export default rootSaga;

import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import { DataNotFound } from '../../components/common';
import { settingActions } from '../../config/actions';

function PageNotFound() {
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    dispatch(settingActions.setSelectedMenu(location));
    navigate('/', { replace: true });
  }, [location]);

  return (
    <div className="d-flex vh-100" style={{ overflow: 'hidden' }}>
      <div className="mx-auto my-auto p-4 text-center">
        <DataNotFound
          message="404 - The Page you are trying to access Does not Exist"
          className="fw-bold tex-sm"
        />
      </div>
    </div>
  );
}

export default PageNotFound;

import update from 'immutability-helper';
import { findIndex } from 'lodash';
import { verificationActions } from '../../actions';
import initialState from '../../initialState';

function metadata(state = initialState.verification, actions) {
  switch (actions.type) {
    case verificationActions.VERIFY_STUDENT_REQUEST:
      return {
        ...state,
        verifyError: {},
        verifying: true,
        studentNumber: actions?.data?.studentNumber,
      };
    case verificationActions.VERIFY_STUDENT_SUCCESS: {
      const { studentNumber, data } = actions;
      const { verifiedStudents } = state;

      const findDataIndex = findIndex(
        verifiedStudents,
        (student) => student.studentNumber === studentNumber
      );

      const newStateData = { studentNumber, ...data };

      let newState = update(state, {
        verifiedStudents: { $push: [newStateData] },
      });
      if (findDataIndex !== -1) {
        newState = update(state, {
          verifiedStudents: { [findDataIndex]: { $set: newStateData } },
        });
      }
      return {
        ...newState,
        verifying: false,
      };
    }
    case verificationActions.VERIFY_STUDENT_ERROR:
      return {
        ...state,
        verifyError: actions.error,
        verifying: false,
      };

    case verificationActions.VERIFY_STUDENT_TRANSCRIPT_REQUEST:
      return {
        ...state,
        verifyTranscriptError: {},
        verifyingTranscript: true,
        params: actions?.data,
      };
    case verificationActions.VERIFY_STUDENT_TRANSCRIPT_SUCCESS: {
      const { data, params } = actions;
      const { verifiedTranscripts } = state;

      const findDataIndex = findIndex(
        verifiedTranscripts,
        (transcript) =>
          transcript?.token === params?.token &&
          transcript?.studentIdentification === params?.studentIdentification
      );

      const newStateData = { ...params, ...data };

      let newState = update(state, {
        verifiedTranscripts: { $push: [newStateData] },
      });
      if (findDataIndex !== -1) {
        newState = update(state, {
          verifiedTranscripts: { [findDataIndex]: { $set: newStateData } },
        });
      }
      return {
        ...newState,
        verifyingTranscript: false,
      };
    }
    case verificationActions.VERIFY_STUDENT_TRANSCRIPT_ERROR:
      return {
        ...state,
        verifyTranscriptError: actions.error,
        verifyingTranscript: false,
      };
    default:
      return state;
  }
}

export default metadata;

import appInitialState from './app';
import setting from './setting';
import tab from './tab';

const initialStates = {
  ...appInitialState,
  ...setting,
  tab,
};

export default initialStates;

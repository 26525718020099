const verification = {
  VERIFY_STUDENT_REQUEST: 'VERIFY_STUDENT_REQUEST',
  VERIFY_STUDENT_SUCCESS: 'VERIFY_STUDENT_SUCCESS',
  VERIFY_STUDENT_ERROR: 'VERIFY_STUDENT_ERROR',

  VERIFY_STUDENT_TRANSCRIPT_REQUEST: 'VERIFY_STUDENT_TRANSCRIPT_REQUEST',
  VERIFY_STUDENT_TRANSCRIPT_SUCCESS: 'VERIFY_STUDENT_TRANSCRIPT_SUCCESS',
  VERIFY_STUDENT_TRANSCRIPT_ERROR: 'VERIFY_STUDENT_TRANSCRIPT_ERROR',

  verifyStudent: (data) => ({
    type: verification.VERIFY_STUDENT_REQUEST,
    data,
  }),

  verifyTranscript: (data) => ({
    type: verification.VERIFY_STUDENT_TRANSCRIPT_REQUEST,
    data,
  }),
};

export default verification;

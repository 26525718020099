import { combineReducers } from 'redux';
import metadata from './app/metadata';
import verification from './app/verification';
import server from './app/server';
import setting from './setting';
import tab from './tab';

const appReducer = combineReducers({
  setting,
  verification,
  metadata,
  server,
  tab,
});

const rootReducer = (state, actions) => {
  return appReducer(state, actions);
};

export default rootReducer;

const tabState = {
  programmeTab: {
    active: 'programmes',
    activeView: 'details',
  },

  programmeVersionTab: {
    active: 'view',
  },

  feesWaiversTab: {
    active: 'view',
  },

  otherFeesTab: {
    active: 'view',
  },

  waiverDiscountsTab: {
    active: 'view',
  },
  academicYearTab: {
    active: 'view-table',
  },
  academicYearEventTab: {
    active: 'view-table',
  },

  viewProgrammeTab: {
    active: 'view-all-programme',
  },
};

export default tabState;

import { takeLatest, fork, put } from 'redux-saga/effects';
import axios from 'axios';
import { serverActions } from '../../actions';

function* verifyGoogleToken(actions) {
  try {
    const response = yield axios({
      url: 'recaptcha/api/siteverify',
      method: 'POST',
      baseURL: 'https://www.google.com/',
      data: actions.data,
    });
    yield put({
      type: serverActions.VERIFY_GOOGLE_RECAPTURE_SUCCESS,
      data: response.data,
    });
  } catch (error) {
    yield put({
      type: serverActions.VERIFY_GOOGLE_RECAPTURE_ERROR,
      error: error.data,
    });
  }
}

function* getAllInstitutionStructure() {
  try {
    const response = yield axios({
      url: '/app/institution-structure/student-portal',
      method: 'GET',
    });
    yield put({
      type: serverActions.GET_INSTITUTION_STRUCTURE_SUCCESS,
      data: response.data,
    });
  } catch (error) {
    yield put({
      type: serverActions.GET_INSTITUTION_STRUCTURE_ERROR,
      error: error.data,
    });
  }
}

function* watchVerifyGoogleToken() {
  yield takeLatest(
    serverActions.VERIFY_GOOGLE_RECAPTURE_REQUEST,
    verifyGoogleToken
  );
}

function* watchGetInstitutionStructure() {
  yield takeLatest(
    serverActions.GET_INSTITUTION_STRUCTURE_REQUEST,
    getAllInstitutionStructure
  );
}

const forkFunctions = [
  fork(watchVerifyGoogleToken),
  fork(watchGetInstitutionStructure),
];

export default forkFunctions;

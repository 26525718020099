const appInitialState = {
  server: {
    error: {},
    success: {},

    verifyingGoogleToken: false,
    verifyGoogleTokenResponse: {},
    verifyGoogleTokenError: {},
  },
  metadata: {
    metadata: [],
    singleMetadata: {},
    metadataError: {},
    loading: false,
  },
  institutionStructure: {
    institutionStructure: {},
    institutionStructureError: {},
    gettingInstitutionStructure: false,
  },
  verification: {
    verifying: false,
    verifyError: {},
    verifiedStudents: [],

    verifyingTranscript: false,
    verifyTranscriptError: {},
    verifiedTranscripts: [],

    params: {},

    studentNumber: null,
  },
};

export default appInitialState;

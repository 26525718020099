import { takeLatest, fork, put } from 'redux-saga/effects';
import axios from 'axios';
import { verificationActions } from '../../actions';

function* verifyStudent(actions) {
  try {
    const { data } = actions;
    const response = yield axios({
      url: '/verifications/registration',
      method: 'GET',
      params: data,
    });
    yield put({
      type: verificationActions.VERIFY_STUDENT_SUCCESS,
      data: response.data,
      ...data,
    });
  } catch (error) {
    yield put({
      type: verificationActions.VERIFY_STUDENT_ERROR,
      error: error.data,
    });
  }
}

function* verifyTranscript(actions) {
  try {
    const { data } = actions;
    const response = yield axios({
      url: '/app/document-settings/doc/preview/transcript',
      method: 'GET',
      params: data,
    });
    yield put({
      type: verificationActions.VERIFY_STUDENT_TRANSCRIPT_SUCCESS,
      data: response.data,
      params: data,
    });
  } catch (error) {
    yield put({
      type: verificationActions.VERIFY_STUDENT_TRANSCRIPT_ERROR,
      error: error.data,
    });
  }
}

function* watchVerifyStudent() {
  yield takeLatest(verificationActions.VERIFY_STUDENT_REQUEST, verifyStudent);
}

function* watchVerifyTranscript() {
  yield takeLatest(
    verificationActions.VERIFY_STUDENT_TRANSCRIPT_REQUEST,
    verifyTranscript
  );
}

const forkFunctions = [fork(watchVerifyStudent), fork(watchVerifyTranscript)];

export default forkFunctions;

import { Image } from 'antd';
import PropTypes, { number, object, string } from 'prop-types';

import React from 'react';

function AntImage({ src, alt, fallback, className, style, width, ...props }) {
  return (
    <>
      <Image
        src={src}
        alt={alt}
        className={`text-center${className}`}
        preview={false}
        draggable={false}
        style={style}
        fallback={fallback}
        height="100"
        width={width}
        {...props}
      />
    </>
  );
}

AntImage.defaultProps = {
  alt: 'image',
  className: '',
  src: null,
  fallback: null,
  style: {},
  width: 60,
};

AntImage.propTypes = {
  alt: PropTypes.string,
  src: PropTypes.string,
  fallback: PropTypes.string,
  style: PropTypes.oneOfType([object]),
  width: PropTypes.oneOfType([number, string]),
  className: PropTypes.string,
};

export default AntImage;

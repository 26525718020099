import React, { Suspense, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import RoutePaths from './config/routes/routePaths';
import PageLoader from './containers/Dashboard/PageLoader';
import ErrorBoundary from './containers/Errors/ErrorBoundary';
import PageNotFound from './containers/Errors/PageNotFound';
import './custom.scss';
import ToggleTheme from './components/shared/ToggleTheme';
import { getThemeSettings } from './helpers/themeSetting';

const Dashboard = React.lazy(() => import('./containers/Dashboard'));
const TranscriptVerification = React.lazy(
  () => import('./containers/TranscriptVerification')
);

function App() {
  const settings = getThemeSettings();
  const [darkMode, setDarkMode] = useState(settings?.isDarkMode || false);

  return (
    <Suspense fallback={<PageLoader />}>
      <ErrorBoundary>
        <Router basename="/">
          <Routes>
            <Route
              exact
              path={RoutePaths.dashboard.path}
              element={<Dashboard />}
              caseSensitive
            />
            <Route
              exact
              path={RoutePaths.transcriptVerification.path}
              element={<TranscriptVerification />}
              caseSensitive
            />
            <Route path="*" element={<PageNotFound />} />
          </Routes>
        </Router>
        <ToggleTheme darkMode={darkMode} setDarkMode={setDarkMode} />
      </ErrorBoundary>
    </Suspense>
  );
}

export default App;
